var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "div",
        { staticClass: "ma-0 pa-0 d-inline", on: { click: _vm.openModal } },
        [
          _vm._t("default", function() {
            return [
              _vm.edit
                ? _c(
                    "b-button",
                    { attrs: { variant: "warning", block: "" } },
                    [
                      _c("b-icon", { attrs: { icon: "pencil" } }),
                      _vm._v(" Edit Document ")
                    ],
                    1
                  )
                : _c(
                    "b-button",
                    { attrs: { variant: "success", block: "" } },
                    [
                      _c("b-icon", { attrs: { icon: "plus" } }),
                      _vm._v(" Add Document ")
                    ],
                    1
                  )
            ]
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "documentmodal" + _vm.documentId,
            title: (_vm.edit ? "Edit" : "Add") + " Document",
            "ok-variant": "success",
            busy: _vm.loading,
            "no-close-on-backdrop": "",
            "no-close-on-esc": ""
          },
          on: {
            hidden: function($event) {
              return _vm.reset()
            },
            shown: function($event) {
              return _vm.init()
            },
            ok: _vm.updateDocument
          },
          scopedSlots: _vm._u([
            {
              key: "modal-ok",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "check" } }),
                  _vm._v(" Save ")
                ]
              },
              proxy: true
            },
            {
              key: "modal-cancel",
              fn: function() {
                return [
                  _c("b-icon", { attrs: { icon: "x" } }),
                  _vm._v(" Cancel ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.isBookkeeper
            ? _c(
                "b-form-group",
                { attrs: { label: "Document Type", "label-align": "left" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.types,
                      "text-field": "name",
                      "value-field": "id"
                    },
                    on: { change: _vm.resetFields },
                    model: {
                      value: _vm.document.documenttype_id,
                      callback: function($$v) {
                        _vm.$set(_vm.document, "documenttype_id", $$v)
                      },
                      expression: "document.documenttype_id"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBookkeeper && _vm.showField("year")
            ? _c(
                "b-form-group",
                { attrs: { label: "Year", "label-align": "left" } },
                [
                  _c("b-input", {
                    model: {
                      value: _vm.document.year,
                      callback: function($$v) {
                        _vm.$set(_vm.document, "year", $$v)
                      },
                      expression: "document.year"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBookkeeper && _vm.showField("month")
            ? _c(
                "b-form-group",
                { attrs: { label: "Month", "label-align": "left" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.months },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c(
                                "b-select-option",
                                { attrs: { value: null, disabled: "" } },
                                [_vm._v("-- Select One --")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      482742255
                    ),
                    model: {
                      value: _vm.document.month,
                      callback: function($$v) {
                        _vm.$set(_vm.document, "month", $$v)
                      },
                      expression: "document.month"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.isBookkeeper && _vm.showField("quarter")
            ? _c(
                "b-form-group",
                { attrs: { label: "Quarter", "label-align": "left" } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.quarters },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c(
                                "b-select-option",
                                { attrs: { value: null, disabled: "" } },
                                [_vm._v("-- Select One --")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      482742255
                    ),
                    model: {
                      value: _vm.document.quarter,
                      callback: function($$v) {
                        _vm.$set(_vm.document, "quarter", $$v)
                      },
                      expression: "document.quarter"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            { attrs: { label: "File", "label-align": "left" } },
            [
              _vm.documentId > 0 && !_vm.deleteDoc && _vm.hasFile
                ? _c(
                    "b-input-group",
                    [
                      _c("b-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.existingFilename,
                          callback: function($$v) {
                            _vm.existingFilename = $$v
                          },
                          expression: "existingFilename"
                        }
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  _vm.deleteDoc = true
                                }
                              }
                            },
                            [_c("b-icon", { attrs: { icon: "x" } })],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "custom-file-container" }, [
                _c("input", {
                  ref: "fileInput",
                  staticClass: "custom-file-input",
                  attrs: { type: "file", id: "fileInput", multiple: "" },
                  on: { change: _vm.handleFiles }
                }),
                _c(
                  "label",
                  {
                    staticClass: "custom-file-label",
                    attrs: { for: "fileInput" }
                  },
                  [_vm._v(" " + _vm._s(_vm.fileLabel) + " ")]
                )
              ])
            ],
            1
          ),
          _c("b-overlay", { attrs: { show: _vm.loading, "no-wrap": "" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }