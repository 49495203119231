var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", sm: "1" } },
            [_c("back-button")],
            1
          ),
          _c(
            "b-col",
            {
              staticClass: "mb-4 d-flex align-items-center",
              attrs: { cols: "auto" }
            },
            [
              _vm.bkUser
                ? _c(
                    "b-form-checkbox",
                    {
                      staticClass: "text-white",
                      model: {
                        value: _vm.filters.allCompanies,
                        callback: function($$v) {
                          _vm.$set(_vm.filters, "allCompanies", $$v)
                        },
                        expression: "filters.allCompanies"
                      }
                    },
                    [_vm._v(" All Companies ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mb-4", attrs: { cols: "12", sm: "10", xl: "7" } },
            [
              _c(
                "b-form-group",
                { staticClass: "m-0" },
                [
                  _c("b-select", {
                    attrs: {
                      options: _vm.dropdownOptions,
                      "text-field": "name",
                      "value-field": "id"
                    },
                    on: { change: _vm.selectCompany },
                    model: {
                      value: _vm.company.id,
                      callback: function($$v) {
                        _vm.$set(_vm.company, "id", $$v)
                      },
                      expression: "company.id"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.company.id
            ? _c(
                "b-col",
                { staticClass: "mb-4", attrs: { cols: "12", xl: "3" } },
                [
                  _c("DocumentModal", {
                    attrs: {
                      compId: _vm.company.id,
                      companyName: _vm.company.name
                    },
                    on: { refresh: _vm.refresh }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "text-left",
                          attrs: { cols: "12", xl: "9" }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "d-inline mr-4",
                              attrs: { label: "Year", "label-align": "left" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.years },
                                scopedSlots: _vm._u([
                                  {
                                    key: "first",
                                    fn: function() {
                                      return [
                                        _c("b-form-select-option", {
                                          attrs: { value: null }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ]),
                                model: {
                                  value: _vm.filters.year,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "year", $$v)
                                  },
                                  expression: "filters.year"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "d-inline mr-4",
                              attrs: { label: "Month", "label-align": "left" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.months },
                                model: {
                                  value: _vm.filters.month,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "month", $$v)
                                  },
                                  expression: "filters.month"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "d-inline mr-4",
                              attrs: { label: "Quarter", "label-align": "left" }
                            },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.quarters },
                                model: {
                                  value: _vm.filters.quarter,
                                  callback: function($$v) {
                                    _vm.$set(_vm.filters, "quarter", $$v)
                                  },
                                  expression: "filters.quarter"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "d-inline",
                              attrs: {
                                label: "Document Type",
                                "label-align": "left"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.doctypes,
                                  "text-field": "name",
                                  "value-field": "id"
                                },
                                model: {
                                  value: _vm.filters.documenttype_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.filters,
                                      "documenttype_id",
                                      $$v
                                    )
                                  },
                                  expression: "filters.documenttype_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", xl: "3" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-4",
                              attrs: { variant: "info" },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_vm._v("Search")]
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.resetfilters()
                                }
                              }
                            },
                            [_vm._v("Reset")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.isPdf(_vm.selectedDocumentUrl)
            ? _c(
                "b-modal",
                {
                  attrs: {
                    title: "Preview",
                    size: "xl",
                    "ok-only": "",
                    "ok-variant": "danger",
                    "ok-title": "Close"
                  },
                  model: {
                    value: _vm.modalShow,
                    callback: function($$v) {
                      _vm.modalShow = $$v
                    },
                    expression: "modalShow"
                  }
                },
                [
                  _c("iframe", {
                    attrs: {
                      src: _vm.selectedDocumentUrl,
                      width: "100%",
                      height: "500px"
                    }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "text--black" },
        [
          _vm.filters.totalItems > _vm.filters.max
            ? _c("b-pagination", {
                staticClass: "float-right",
                attrs: {
                  "total-rows": _vm.filters.totalItems,
                  "per-page": _vm.filters.max,
                  "aria-controls": "documentsTable"
                },
                on: { change: _vm.changePage },
                model: {
                  value: _vm.filters.page,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "page", $$v)
                  },
                  expression: "filters.page"
                }
              })
            : _vm._e(),
          _c("b-table", {
            attrs: {
              id: "documentsTable",
              striped: "",
              items: _vm.files,
              fields: _vm.fields,
              "no-local-sorting": "",
              busy: _vm.loading,
              "show-empty": "",
              "sort-desc": "",
              "sort-by": "updated",
              stacked: "lg"
            },
            on: { "sort-changed": _vm.sortingChange },
            scopedSlots: _vm._u([
              {
                key: "cell(id)",
                fn: function(data) {
                  return [
                    data.item.thumbnail
                      ? _c(
                          "b-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toggleModal(data.item.documenturl)
                              }
                            }
                          },
                          [_c("img", { attrs: { src: data.item.thumbnail } })]
                        )
                      : _c(
                          "b-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toggleModal(data.item.documenturl)
                              }
                            }
                          },
                          [
                            _c("b-icon", {
                              staticClass: "h1",
                              attrs: { icon: "file-earmark-text-fill" }
                            })
                          ],
                          1
                        )
                  ]
                }
              },
              {
                key: "cell(documenttype_id)",
                fn: function(data) {
                  return [_vm._v(" " + _vm._s(data.item.name) + " ")]
                }
              },
              {
                key: "cell(documentname)",
                fn: function(data) {
                  return [
                    data.item.documenturl && _vm.isPdf(data.item.documenturl)
                      ? _c(
                          "span",
                          {
                            staticClass: "text--sbs-red cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.toggleModal(data.item.documenturl)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("docname")(data.item.documentname))
                            )
                          ]
                        )
                      : data.item.downloadurl &&
                        !_vm.isPdf(data.item.documenturl)
                      ? _c(
                          "span",
                          { staticClass: "text--sbs-red cursor-pointer" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "text--sbs-red cursor-pointer",
                                attrs: { href: data.item.downloadurl }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("docname")(data.item.documentname)
                                  )
                                )
                              ]
                            )
                          ]
                        )
                      : _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("docname")(data.item.documentname))
                          )
                        ])
                  ]
                }
              },
              {
                key: "cell(updated)",
                fn: function(data) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("date")(data.item.updated)) + " "
                    )
                  ]
                }
              },
              {
                key: "cell(action)",
                fn: function(data) {
                  return [
                    data.item.documenttype_id != 15
                      ? _c(
                          "div",
                          [
                            _c("DocumentModal", {
                              attrs: {
                                compId: _vm.company.id,
                                documentId: +data.item.id,
                                edit: ""
                              },
                              on: { refresh: _vm.refresh }
                            }),
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-2",
                                attrs: { block: "", variant: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteDocument(data.item)
                                  }
                                }
                              },
                              [
                                _c("b-icon", { attrs: { icon: "trash" } }),
                                _vm._v(" Delete")
                              ],
                              1
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "text-decoration-none",
                                attrs: { href: data.item.downloadurl }
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-2",
                                    attrs: { block: "", variant: "success" }
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "download" }
                                    }),
                                    _vm._v(" Download")
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm.filters.totalItems > _vm.filters.max
            ? _c("b-pagination", {
                staticClass: "float-right",
                attrs: {
                  "total-rows": _vm.filters.totalItems,
                  "per-page": _vm.filters.max,
                  "aria-controls": "documentsTable"
                },
                on: { change: _vm.changePage },
                model: {
                  value: _vm.filters.page,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "page", $$v)
                  },
                  expression: "filters.page"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }